<template>
  <div id="content-wrapper" style="background: #ffffff">
    <div class="container">
      <div class="row">
        <div
          class="project-single col-sm-12 mar-top-0"
          v-if="windowEthereumEnabled"
        >
          <h2>Connect your Wallet</h2>
          <img src="/images/metamask.png" style="width: 300px;" />
          <br /><br /><br />
          <button class="mint-button" v-on:click="connectWallet">
            Connect Wallet
          </button>
        </div>
        <div class="project-single col-sm-12 mar-top-0" v-else>
          <h2>Ethereum Wallet Not Detected</h2>
          <img src="/images/metamask.png" style="width: 300px;" />
          <br /><br /><br />
          <a href="https://metamask.io/" target="_blank">
            <button class="mint-button">Get Metamask</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoWallet',
  data() {
    return {
      timer: '',
      windowEthereumEnabled: false,
    };
  },
  computed: {
    isWalletConnected() {
      return this.$store.state.isWalletConnected;
    },
  },
  mounted() {
    this.checkWallet();
    this.timer = setInterval(this.checkWallet, 1000);
    if (window.ethereum) {
      this.windowEthereumEnabled = true;
    }
  },
  methods: {
    async checkWallet() {
      if (this.isWalletConnected) {
        this.$router.push(this.$route.query.redirect || '/');
      }
    },
    async connectWallet() {
      await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style></style>
